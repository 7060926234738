$crmoffers-progress-main-color: #1c75bc;
$crmoffers-primary-color: #16556d;
$crmoffers-button-color: #ee8336;
$crmoffers-form-border-color: #2e2e2e;
$crmoffers-default-button-hover-bgcolor: #6e95a4;

:export {
    progressColor: $crmoffers-progress-main-color;
    primaryColor: $crmoffers-primary-color;
}
